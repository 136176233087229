import { render, staticRenderFns } from "./DiseaseAdd.vue?vue&type=template&id=23fc33b7&scoped=true&"
import script from "./DiseaseAdd.vue?vue&type=script&lang=ts&"
export * from "./DiseaseAdd.vue?vue&type=script&lang=ts&"
import style0 from "./DiseaseAdd.vue?vue&type=style&index=0&id=23fc33b7&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23fc33b7",
  null
  
)

export default component.exports