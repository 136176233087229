
import { Component, Vue } from 'vue-property-decorator'
import { initWangEdit } from '@/utils/wangEdit'
import UploadFile from '@/components/uploadFile/Index.vue'
import { DiseaseAddInfo } from '@/types/pestControl'
import { FileInfo } from '@/types/common'
import { ElForm } from 'element-ui/types/form'

@Component({
  components: { UploadFile },
  filters: {
    imgListFilter (arr: Array<FileInfo>) {
      return arr.map(item => {
        return item.filePrefix + item.fileUrl
      })
    }
  }
})
export default class DiseaseAdd extends Vue {
  private typeList = []
  private monthList = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
  private happenTimeList = []
  private info: DiseaseAddInfo = {
    diseasesName: '',
    happenArea: '',
    diseasesOtherName: '',
    harmPlants: '',
    diseasesType: '',
    etiologyType: '',
    medicineKind: '',
    harmPart: '',
    happenTime: '',
    resourceList: [],
    describes: ''
  }

  private rules = {
    diseasesName: [{ required: true, message: '请输入病害名称', trigger: ['blur', 'change'] }],
    diseasesType: [{ required: true, message: '请选择病害类型', trigger: ['change'] }],
    resourceList: [{ required: true, message: '请选择上传病害图片', trigger: ['change'] }]
  }

  private editorDescribes: any = null
  private submitShow = false
  get diseasesId () {
    return this.$route.params.diseasesId || ''
  }

  created () {
    this.getTypeList()
    this.init()
    if (this.diseasesId) {
      this.getDetail()
    }
  }

  destroyed () {
    // 销毁编辑器
    this.editorDescribes.destroy()
    this.editorDescribes = null
  }

  getTypeList () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'diseasesType' }).then(res => {
      this.typeList = res.diseasesType || []
    })
  }

  // 富文本初始化
  init () {
    this.$nextTick(() => {
      this.editorDescribes = initWangEdit('#describes', { placeholder: '请输入描述' })
      this.editorDescribes.create()
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.pestControl.selectYhDiseasesByDiseasesId, {
      diseasesId: this.diseasesId
    }).then(res => {
      this.info = res
      this.happenTimeList = res.happenTime.split(',') || []
      this.editorDescribes.txt.html(res.describes || '')
    })
  }

  onSuccess (file: FileInfo) {
    this.info.resourceList.push(file)
  }

  imgRemove (index: number) {
    this.info.resourceList.splice(index, 1)
  }

  onSubmit () {
    // 必填项赋值
    // 获取富文本内容
    ;(this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const url = this.diseasesId ? this.$apis.pestControl.updateYhDiseases : this.$apis.pestControl.insertYhDiseases
        this.info.happenTime = this.happenTimeList.length > 0 ? this.happenTimeList.join(',') : ''
        this.info.describes = this.editorDescribes.txt.html()
        this.$axios.post(url, this.info).then(() => {
          this.$message.success('保存成功')
          this.$router.push({ name: 'disease' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
